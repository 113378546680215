import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const LockIcon = ({
  className,
  color,
  size,
  title,
  strokeWidth,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      stroke={color || 'currentColor'}
      strokeWidth={strokeWidth || 1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}>
      {title && <title>{title}</title>}
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
    </BaseSvgIcon>
  )
}
