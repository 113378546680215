import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export function SoftXIcon({
  className,
  color,
  size,
  title,
  strokeWidth = 2.4,
  ...rest
}: BaseSvgIconProps) {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 10 9"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M7.99998 1.50002L2 7.5M1.99997 1.5L7.99995 7.49998"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </BaseSvgIcon>
  )
}
